































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class DashboardSettings extends Vue {
  menuItems: {
    label: string,
    icon: string,
    path: string,
  }[] = [
    {
      label: 'General',
      icon: 'mdi-tune',
      path: 'general',
    },
    {
      label: 'Style',
      icon: 'mdi-brush',
      path: 'style',
    },
    {
      label: 'Permissions',
      icon: 'mdi-account-check',
      path: 'permissions',
    },
    {
      label: 'Variables',
      icon: 'mdi-currency-usd',
      path: 'variables',
    },
  ]
}
